<template>
  <div class="checkout">
    <div class="container">
      <div class="row">
        <div class="col-12" v-if="order !== null">
          <h1>
            Your order
          </h1>
          <div class="checkout__product">
            <div class="product__info">
              <div class="left">
                <div
                  class="poster"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img/diamond.svg') + ')',
                  }"
                >
                  <div
                    class="poster-image"
                    v-if="order.product.image_url"
                    :style="{
                      backgroundImage: 'url(' + order.product.image_url + ')',
                    }"
                  ></div>
                </div>
              </div>
              <div class="right">
                <h1>
                  {{
                    $utils.getLocaleProductTitle(order.product, $i18n.locale)
                  }}
                </h1>
                <div class="price">
                  ${{ order.product.price }}

                  <span v-if="order.product.price_in_coins">
                    <img src="@/assets/img/coin.png" alt="" />
                    {{ order.product.price_in_coins }}
                  </span>
                </div>
                <div class="reward">
                  <span>
                    {{ $t("reward") }}
                  </span>
                  <div class="d-flex amount">
                    <img src="@/assets/img/coin.png" alt="" />
                    {{ order.product.coins }}
                  </div>
                </div>
                <div class="round-info d-flex">
                  <div class="selected mt-4">
                    <label class="d-block">
                      {{ $t("selected_number") }}
                    </label>
                    {{ order.seat }}
                  </div>
                  <div class="round-number mt-4">
                    <label class="d-block">{{ $t("round_number") }}</label>
                    {{ order.round.number }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="checkout__shipping"></div>
          <div class="checkout__total">
            <div class="text-center py-4">
              {{ $t("total") }}: <strong>${{ order.product.price }}</strong>
            </div>
          </div>
          <b-overlay
            :show="isLoading"
            rounded="sm"
            variant="dark"
            bgColor="#16193e"
          >
            <div
              class="checkout__pay-buttons py-4 d-flex align-items-center flex-column"
            >

            <!--Button for sending payment request-->

              <b-button
                :disabled="isLoading"
                variant="primary has-icon"
                class="mb-2"
                @click="createOrder('card')"
              >
                <icon variant="card" />
                {{ $t("pay_with_card") }}
              </b-button>

            <!--Button for sending payment request-->

              <b-button
                @click="createOrder('coins')"
                v-if="order.product.sale_for_coins && order.product.price_in_coins"
                variant="dark has-icon"
                :disabled="isLoading"
                class="mb-2"
              >
                <img src="@/assets/img/coin.png" alt="" />
                {{ $t("pay_with_coins") }} (<span
                  v-if="order.product.price_in_coins"
                >
                  {{ order.product.price_in_coins }} </span
                >)
              </b-button>
            </div>
          </b-overlay>
        </div>
        <div
          class="col-12 d-flex justify-content-center"
          style="height:40vh; align-items:center;"
          v-if="order == null && !order_is_payed"
        >
          <div class="text-center">
            <h1>
              {{ $t("no_product_chosen") }}
            </h1>
            <b-button :to="{ name: 'shop' }" class="mt-2" variant="primary">
              {{ $t("shop_now") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-order-successful"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="modal-icon">
        <div class="text-center">
          <img src="@/assets/img/checked.svg" alt="" />
        </div>
      </div>
      <div class="modal-message text-center">
        <h3>{{ $t("congratulations") }}</h3>
        <p class="text-center">
          <strong> #{{ response_order.id }}</strong>
        </p>
        <p>
          {{ $t("order_was_successfully_placed") }}
        </p>
      </div>
      <div class="d-block w-100 mt-4">
        <b-button
          variant="primary"
          href="/shop"
          block
          class="w-100"
          @click="location.reload();"
        >
          Continue shopping
        </b-button>
        <b-button
          variant="secondary"
          href="/my/orders"
          block
          class="w-100 mt-2"
          @click="location.reload();"
        >
          My orders
        </b-button>
      </div>
    </b-modal>
    <!-- /order successfull modal -->
    <b-modal id="modal-seat-is-busy" hide-footer hide-header centered>
      <div class="modal-icon">
        <div class="text-center">
          <img src="@/assets/img/warning.svg" alt="" />
        </div>
      </div>
      <div class="modal-message text-center" v-if="order">
        Looks like selected number <strong>{{ order.seat }}</strong> is busy
        :(.<br />
        Please choose another number
      </div>
      <div class="d-block w-100 mt-4" v-if="order">
        >
        <b-button
          variant="primary"
          :to="{
            name: 'product',
            params: { id: order.product.uid },
            hash: '#number-selection',
          }"
          block
          class="w-100"
        >
          Select another number
        </b-button>
      </div>
    </b-modal>
    <!-- /seat busy modal -->
    <b-modal id="modal-order-no-coins" hide-footer hide-header centered>
      <div class="modal-icon">
        <div class="text-center">
          <img src="@/assets/img/warning.svg" alt="" />
        </div>
      </div>
      <div class="modal-message text-center">
        Not enough coins to pay order. <br />Please choose another payment
        option.
      </div>
      <div class="d-block w-100 mt-4">
        <b-button
          variant="primary"
          @click="hideModal('modal-order-no-coins')"
          block
          class="w-100"
        >
          Ok
        </b-button>
      </div>
    </b-modal>
    <!-- /seat busy modal -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      order_is_payed: false,
      response_order: {},
    };
  },
  computed: {
    ...mapGetters({
      order: "order",
      user: "user",
    }),
  },
  methods: {
    hideModal(id) {
      this.$bvModal.hide(id);
    },
    async createOrder(payment) {
      this.isLoading = true;
      var order = this.order;
      var currency = "usd";
      if (payment == "coins") {
        currency = "coins";
      }
      var orderToCreate = {
        product: order.product.uid,
        product_price: order.product.price,
        product_price_in_coins: order.product.price_in_coins,
        payment: payment,
        currency: currency,
        purchase_reward: order.product.coins,
        user: this.user.uid,
        round_number: order.round.number,
        seat: order.seat,
      };
      this.$api
        .post("/post/order/", orderToCreate)
        .then((response) => {
          console.log(response)
          if(response.data.url && response.data.currency == "usd") {
            window.location = response.data.url
          }
          if (response.data.status == false && response.data.currency == "coins") {
            if (response.data.message == "seat-is-busy") {
              this.$bvModal.show("modal-seat-is-busy");
            }
          } else {
            if (response.data.payed == true) {
              // Если оплата прошла успешно, показываем модальное окно
              this.response_order = response.data.order;
              this.order_is_payed = true;
              this.$bvModal.show("modal-order-successful");
              this.$store.dispatch("setOrder", null);
            } else if (response.data.payed == false && response.data.currency == "coins") {
              this.$bvModal.show("modal-order-no-coins");
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout {
  &__total {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    strong {
      font-size: 28px;
      font-weight: bold;
    }
  }
  &__pay-buttons {
    button {
      width: 320px;
      max-width: 100%;
      height: 56px;
      font-size: 22px;
      line-height: 1;
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
  .round-info {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    .selected {
      margin-right: 20px;
    }
    label {
      font-size: 14px;
      font-weight: 300;
    }
  }
  .product__info {
    display: flex;
    padding-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(#ffbd2c, 0.3);
    border-bottom: 1px solid rgba(#ffbd2c, 0.3);
    .left {
      width: 180px;
      min-width: 180px;
      margin-right: 30px;
      .poster {
        padding-top: 140%;
        background: #6b6969;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30% auto;
        position: relative;
        .poster-image {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-position: center;
          background-size: cover;
        }
      }
    }

    .right {
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #ffffff;
      }
      .price {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 19px;
        color: #ffbd2c;
        span {
          color: #fff;
          font-size: 14px;
          img {
            height: 12px;
          }
        }
      }
      .reward {
        margin-top: 10px;
        .amount {
          align-items: center;
          color: #fff;
          img {
            height: 16px;
            margin-right: 2px;
            transform: translateX(-4px);
          }
        }
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #888dff;
        }
      }
      .description {
        margin-top: 20px;
        color: #fff;
      }
    }
  }
}
</style>
